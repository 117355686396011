import {
  any,
  equals,
  filter,
  find,
  includes,
  isEmpty,
  isNil,
  keys,
  map,
  propEq,
  Reduced,
  test,
} from "ramda";

import { CHECKOUT_MODAL_CONFIGURATION, CheckoutState } from "./useRequest";

export const VALIDATION = {
  MAX_LENGTH_INPUT: "MAX_LENGTH_INPUT",
  MAX_LENGTH_COMMENT: "MAX_LENGTH_COMMENT",
  MANDATORY_FIELDS: "MANDATORY_FIELDS",
  VALIDATION_FIELDS: "VALIDATION_FIELDS",
};

const isNilOrEmpty = (value: string | null, validationTypes: string[]) =>
  isNil(value) || isNil(validationTypes) || isEmpty(validationTypes);

const isViolationNilOrEmpty = (violation: any) =>
  isNil(violation) || isEmpty(violation);

const anyValidationType = (
  validationString: string,
  validationTypes: string[],
): boolean =>
  any((validationType) => equals(validationType, validationString))(
    validationTypes,
  );

const hasMandatoryTypeAndIsEmptyString = (
  value: string | null,
  validationTypes: string[],
): boolean =>
  anyValidationType(VALIDATION.MANDATORY_FIELDS, validationTypes) &&
  equals(value, "");

export const isMandatoryField = (value: string): boolean => {
  return includes(value, CHECKOUT_MODAL_CONFIGURATION.MANDATORY_FIELDS);
};

const checkMaxLength = (value: string, maxLength: number) =>
  value.length > maxLength;

const hasValidationTypeAndMaxLength = (
  validationTypes: string[],
  value: string | null,
  validation: string,
  maxLength: number,
) =>
  anyValidationType(validation, validationTypes) &&
  checkMaxLength(value + "", maxLength);

export const validate = (
  validationTypes: string[],
  key: string,
  value: string | null,
) => {
  const noViolation = { validation: true, validationMessage: null };

  if (isNilOrEmpty(value, validationTypes)) {
    return noViolation;
  }

  const isValidMaxLength = () => {
    if (
      hasValidationTypeAndMaxLength(
        validationTypes,
        value,
        VALIDATION.MAX_LENGTH_INPUT,
        CHECKOUT_MODAL_CONFIGURATION.MAX_LENGTH_INPUT,
      )
    ) {
      return {
        validation: false,
        validationMessage: "inputValidation.maxLength",
      };
    }
    if (
      hasValidationTypeAndMaxLength(
        validationTypes,
        value,
        VALIDATION.MAX_LENGTH_COMMENT,
        CHECKOUT_MODAL_CONFIGURATION.MAX_LENGTH_COMMENT,
      )
    ) {
      return {
        validation: false,
        validationMessage: "inputValidation.maxLength",
      };
    }
    return noViolation;
  };

  const isValidMandatory = () => {
    if (hasMandatoryTypeAndIsEmptyString(value, validationTypes)) {
      return {
        validation: false,
        validationMessage: "inputValidation.mandatory",
      };
    }
    return noViolation;
  };

  const isValidInput = () => {
    if (anyValidationType(VALIDATION.VALIDATION_FIELDS, validationTypes)) {
      const isViolation = !any((_violation) => Boolean(_violation))(
        map(
          (validationField) =>
            !isEmpty(value) &&
            equals(validationField.value, key) &&
            !test(validationField.validation, value + ""),
          CHECKOUT_MODAL_CONFIGURATION.VALIDATION_FIELDS,
        ),
      );
      return isViolation
        ? noViolation
        : {
            validation: false,
            validationMessage: "inputValidation.invalidInput",
          };
    }
    return noViolation;
  };

  const violation = find(propEq("validation", false), [
    isValidMaxLength(),
    isValidInput(),
    isValidMandatory(),
  ]);
  return isViolationNilOrEmpty(violation) ? noViolation : violation;
};

export const isAnyRequiredInputFieldValid = (checkoutState: CheckoutState) => {
  const isValid = filter(
    (key) =>
      any(
        ({ value, validation }) =>
          !isEmpty(checkoutState[key]) &&
          equals(key, value) &&
          !test(validation, checkoutState[key] + ""),
      )(CHECKOUT_MODAL_CONFIGURATION.VALIDATION_FIELDS),
    keys(checkoutState),
  );
  const isMandatory = filter(
    (key) =>
      any(
        (internalKey) =>
          equals(key, internalKey) && isEmpty(checkoutState[key]),
      )(CHECKOUT_MODAL_CONFIGURATION.MANDATORY_FIELDS),
    keys(checkoutState),
  );
  const isValidMaxLength = filter(
    (key) =>
      //@ts-expect-error might return string instead of boolean
      (!equals(key, "comment") &&
        checkoutState[key].toString().length >
          CHECKOUT_MODAL_CONFIGURATION.MAX_LENGTH_INPUT) ||
      (checkoutState["comment"] &&
        checkoutState["comment"].length >
          CHECKOUT_MODAL_CONFIGURATION.MAX_LENGTH_COMMENT),
    keys(checkoutState),
  );

  return isEmpty([...isValid, ...isMandatory, ...isValidMaxLength]);
};

export function toCheckoutState(
  value: unknown,
): (
  state: CheckoutState,
  stateKey: string,
) => CheckoutState | Reduced<CheckoutState> {
  return (state, stateKey) => ({
    ...state,
    [stateKey]: value,
  });
}
