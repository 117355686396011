import { loadTheme } from "@fluentui/react";
import ReactDOM from "react-dom";

import App from "./App";
import { appTheme } from "./theme";

loadTheme(appTheme);

async function onContainerReady(selector: string): Promise<HTMLElement | null> {
  return new Promise((resolve) => {
    if (document.getElementById(selector)) {
      return resolve(document.getElementById(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.getElementById(selector)) {
        observer.disconnect();
        resolve(document.getElementById(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

onContainerReady("root").then((elem) => {
  ReactDOM.render(<App />, elem);
});
