import { FocusTrapZone, mergeStyleSets, Popup, Stack } from "@fluentui/react";
import { ConfiguratorComponentProps } from "configuration";
import { equals, find, pathOr, replace } from "ramda";
import { useContext, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { getSelectedConnectorParameters } from "../../../../../context/configurationUtils";
import { useConfigurationContext } from "../../../../../context/useConfiguration";
import { ProductContext } from "../../../../../context/useProducts";
import { useVisualization } from "../../../../../hooks/useVisualization";
import { Visualization as CustomVisualization } from "../../../../visualization/Visualization";
import { is3DVisualizationEnabled } from "../../../../visualization/vis3d/vis3dUtils";
import { isInsert } from "../../../components/confTableUtils";
import { SelectedItems } from "../SelectedItems";
import { Visualization } from "../visualization/Visualization";
import { VisualizationProvider } from "../visualization/hooks/useVisualization";

function sectionStyle(isQueryMd: boolean, inPopup: boolean) {
  return mergeStyleSets({
    tableView: {
      borderRadius: "8px",
      boxShadow: "rgba(33, 33, 33, 0.2) 0px 2px 8px 0px",
      boxSizing: "border-box",
      display: "flex",
      flexBasis: "auto",
      flexDirection: "column",
      flexShrink: 0,
      maxWidth: "563px",
      overflow: "hidden",
      overflowX: "auto",
    },
    tableViewPopup: {
      borderRadius: "8px",
      boxShadow: "rgba(33, 33, 33, 0.2) 0px 2px 8px 0px",
      boxSizing: "border-box",
      display: "flex",
      minWidth: "30vw",
      height: "100%",
      overflow: "hidden",
    },
    popupFocus: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      borderRadius: "8px",
      boxShadow: "rgba(33, 33, 33, 0.2) 0px 2px 17px -2px",
      background: "white",
      height: "100%",
      width: "50vw",
    },
    popup: {
      background: "rgba(0, 0, 0, 0.2)",
      inset: 0,
      position: "fixed",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "3em",
      zIndex: 100,
    },
    visualisationWrapper: {
      width: "100%",
      flex: 1,
      flexShrink: 0,
      aspectRatio: isQueryMd && inPopup ? "1 / 0.5" : "1 / 0.65",
    },
    visualisationWrapperPopup: {
      flex: "1 0 calc(66vh - 6em)",
      flexShrink: "0 !important",
      height: "calc(66vh - 6em - 400px)",
      width: "100%",
    },
  });
}

/**
 * The section rendering the visualization, selectedItems and popup.
 */
export function SectionCenter({
  data,
  open,
  setOpen,
  onMore,
  ...props
}: ConfiguratorComponentProps<ContainerTO> & { onMore(): void }) {
  const { visSettings } = useVisualization();
  const [showPopup, setShowPopup] = useState(false);
  const { article, guiTO } = useConfigurationContext();
  const products = useContext(ProductContext);

  const mediaQueryMd = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const sectionStyled = useMemo(
    () => sectionStyle(mediaQueryMd, showPopup),
    [mediaQueryMd, showPopup],
  );

  function popupButtonHandler() {
    setShowPopup((current) => !current);
  }
  if (
    article &&
    guiTO &&
    !is3DVisualizationEnabled(article, products.products)
  ) {
    return (
      <>
        {equals(visSettings.enabled, "table") && <CustomVisualization />}
        <Stack key={data.id} className={sectionStyled.tableView}>
          <TableViewAndSelectedItems
            data={data}
            open={open}
            inPopup={showPopup}
            setOpen={setOpen}
            onMore={onMore}
            {...props}
          />
        </Stack>
      </>
    );
  }
  return (
    //Center section with 3D visualization
    <>
      {article && is3DVisualizationEnabled(article, products.products) ? (
        <VisualizationProvider>
          {!showPopup ? (
            <Stack key={data.id} className={sectionStyled.tableView}>
              <Stack className={sectionStyled.visualisationWrapper}>
                <Visualization
                  popupButtonHandler={popupButtonHandler}
                  inPopup={showPopup}
                />
              </Stack>
              <TableViewAndSelectedItems
                data={data}
                open={open}
                inPopup={showPopup}
                setOpen={setOpen}
                onMore={onMore}
                {...props}
              />
            </Stack>
          ) : (
            <Popup
              className={sectionStyled.popup}
              style={{ overflowY: "hidden" }}
            >
              <FocusTrapZone className={sectionStyled.popupFocus}>
                <Stack key={data.id} className={sectionStyled.tableViewPopup}>
                  <Stack className={sectionStyled.visualisationWrapperPopup}>
                    <Visualization
                      popupButtonHandler={popupButtonHandler}
                      inPopup={showPopup}
                    />
                  </Stack>
                  <TableViewAndSelectedItems
                    data={data}
                    open={open}
                    inPopup={showPopup}
                    setOpen={setOpen}
                    onMore={onMore}
                    {...props}
                  />
                </Stack>
              </FocusTrapZone>
            </Popup>
          )}
        </VisualizationProvider>
      ) : (
        // Center section without visualization
        <Stack key={data.id} className={sectionStyled.tableView}>
          <TableViewAndSelectedItems
            data={data}
            open={open}
            inPopup={showPopup}
            setOpen={setOpen}
            onMore={onMore}
            {...props}
          />
        </Stack>
      )}
    </>
  );
}

function TableViewAndSelectedItems({
  data,
  open,
  inPopup,
  setOpen,
  onMore,
  ...props
}: ConfiguratorComponentProps<ContainerTO> & {
  inPopup: boolean;
  onMore(): void;
}) {
  const {
    guiTO,
    actions: { setProductCrossReferences },
  } = useConfigurationContext();

  const activeParameter = find(
    (parameter) =>
      equals(
        replace(
          /["{}]/g,
          "",
          pathOr<string>("", ["viewPortProperties", "ref-table"], parameter),
        ),
        open.active,
      ),
    data.parameters,
  );

  const onUndo = (parameter: ParameterTO) => {
    if (isInsert(parameter)) {
      setProductCrossReferences([]);
    }
    props.onValueChanged(parameter, "_undo_");
  };

  return (
    <Stack style={{ height: `${inPopup ? "34vh" : "inherit"}` }}>
      <SelectedItems
        parameters={getSelectedConnectorParameters(guiTO)}
        onUndo={onUndo}
      />
      {activeParameter && (
        <Stack key={data.id} style={{ minHeight: 0 }}>
          {ComponentFactory.instanceOf(activeParameter.viewPort!, {
            ...props,
            data: activeParameter,
            key: activeParameter.id,
            open,
            setOpen,
            onMore,
          })}
        </Stack>
      )}
    </Stack>
  );
}
