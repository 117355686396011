import { match, pathOr, replace, toLower } from "ramda";

import { Language } from "@encoway/c-services-js-client";

import { LanguageMapping } from "./useSettings";

export function toLanguage(
  acc: { [locale: string]: Language },
  { displayName, tag }: Language,
): { [locale: string]: Language } {
  return {
    ...acc,
    [tag]: { tag, displayName },
  };
}

/**
 * Retrieves the full location (URL) of the current webpage, excluding any trailing hash symbol.
 * @returns {string} The modified location string without the trailing hash symbol.
 */
export function getFullLocation(fallback: string): string {
  // eslint-disable-next-line no-restricted-globals
  if (window?.location?.href) {
    // eslint-disable-next-line no-restricted-globals
    const [location] = match(/[^#]+/, window.location.href);
    if (location) {
      return replace(/\/$/, "", location);
    }
  }
  return fallback;
}

export function mapLocale(
  languageMapping: LanguageMapping,
  locale = "",
): Array<string> {
  const fallback = [locale, locale];
  return pathOr(fallback, [toLower(locale)], languageMapping);
}
