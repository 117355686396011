import { Products } from "product";
import { forEach, isEmpty, map, path, reduce, uniq } from "ramda";
import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ConfigurationRoutes } from "routes";

import {
  CatalogService,
  Characteristics,
  Filter,
  Selection,
} from "@encoway/c-services-js-client";

import { toExtendedCharacteristic } from "./productUtils";
import { useSettings } from "./useSettings";

export type ProductState = {
  products: Products;

  getProducts(
    ids: string | number | (string | number)[],
    locale?: string,
  ): Promise<Products>;
};

export const ProductContext = createContext<ProductState>({
  products: {},
  getProducts: async function () {
    throw new Error("get not initialized");
  },
});

export const ProductProvider = ProductContext.Provider;

function useProducts(): ProductState {
  const { settings, http } = useSettings();
  const { article } = useParams<ConfigurationRoutes>();
  const { i18n } = useTranslation();
  const catalogService = useMemo(
    () => new CatalogService(http, settings.showroom.url, i18n.language),
    [i18n.language],
  );
  const [products, setProducts] = useState<{ [lang: string]: Products }>({});

  async function fetchProducts(
    ids: string[],
    locale?: string,
  ): Promise<Products> {
    const productsFilter = Filter.productsFilter();
    const lang = locale ?? i18n.language;
    forEach((id) => productsFilter.id(id + ""), ids);
    const selection = new Selection()
      .filter(productsFilter)
      .limit(1000)
      .characteristics(new Characteristics().all());
    const productsResult = await catalogService.products(selection, lang);
    return reduce(
      (acc, product) => {
        const characteristicValues = reduce(
          toExtendedCharacteristic(product.characteristicValues),
          {},
          productsResult.characteristics,
        );
        return { ...acc, [product.id]: { ...product, characteristicValues } };
      },
      {},
      productsResult.products,
    );
  }

  async function getProducts(
    ids: string | number | (string | number)[],
    locale?: string,
  ): Promise<Products> {
    if (isEmpty(ids)) {
      throw new Error("Cannot fetch empty array of products.");
    }
    const lang = locale ?? i18n.language;
    const uIds = Array.isArray(ids)
      ? uniq(map((id) => id + "", ids))
      : [ids + ""];
    if (path<string>([lang], products)) {
      const { cacheIds, fetchIds } = reduce(
        (acc: { cacheIds: string[]; fetchIds: string[] }, id: string) => {
          if (products[lang][id]) {
            return { ...acc, cacheIds: [...acc.cacheIds, id] };
          }
          return { ...acc, fetchIds: [...acc.fetchIds, id] };
        },
        { cacheIds: [], fetchIds: [] },
        uIds,
      );
      const cachedProducts = reduce(
        (acc, _id) => ({ ...acc, [_id]: products[lang][_id] }),
        {},
        cacheIds,
      );
      if (isEmpty(fetchIds)) {
        return cachedProducts;
      }
      const newProducts = await fetchProducts(fetchIds, lang);
      setProducts((prev) => ({
        ...prev,
        [lang]: { ...prev[lang], ...newProducts },
      }));
      return { ...newProducts, ...cachedProducts };
    }
    const newProducts = await fetchProducts(uIds, lang);
    setProducts((prev) => ({
      ...prev,
      [lang]: { ...prev[lang], ...newProducts },
    }));
    return newProducts;
  }

  useEffect(() => {
    if (article) {
      getProducts(article).then();
    }
  }, [article, i18n.language]);

  return useMemo(
    () => ({
      products: products[i18n.language],
      getProducts,
    }),
    [products, i18n.language],
  );
}

export function ProductStore({ children }: Readonly<{ children: ReactNode }>) {
  return <ProductProvider value={useProducts()}>{children}</ProductProvider>;
}
