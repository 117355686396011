import {
  ITheme,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  useTheme,
} from "@fluentui/react";
import { ButtonVisibility } from "bom";
import { equals, isNil, map, or, path, pathOr } from "ramda";
import { ReactNode, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BasicArrowForwardBold } from "../../../components/Icons";
import { useAnalyticsContext } from "../../../context/useAnalytics";
import { BomContext } from "../../../context/useBom";
import { useConfigurationContext } from "../../../context/useConfiguration";
import { retrieveButtonVisibility } from "./bomUtils";
import { ORDER_TYPE } from "./request/useRequest";
import { BomDialogStore } from "./useBomDialog";

function bomButtonsStyle() {
  return mergeStyleSets({
    loading: {
      position: "absolute",
      right: "1em",
    },
  });
}

type BomButtonsProps = {
  cart?: boolean;
  loading: any;
} & Pick<BomDialogStore, "openMail" | "sendToCart" | "toggle">;

function secondaryButtonStyles(theme: ITheme) {
  return mergeStyleSets({
    root: {
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.themePrimary}`,
      boxShadow: "none",
      color: theme.palette.themePrimary,
      padding: "24px 28px",
      selectors: {
        span: {
          fontWeight: "600",
        },
        "&.is-disabled": {
          backgroundColor: theme.palette.white,
          border: `1px solid #9e9e9e`,
          color: "#9e9e9e",
        },
      },
    },
    rootFocused: {
      backgroundColor: theme.palette.white,
      color: theme.palette.themePrimary,
      border: `1px solid rgba(255,113,0,0.80)`,
      boxShadow: "0 2px 8px 0 rgba(33, 33, 33, 0.2)",
      outline: "0",
    },
    rootHovered: {
      backgroundColor: theme.palette.white,
      color: theme.palette.themePrimary,
      border: `1px solid ${theme.palette.themePrimary}`,
      boxShadow: "0 2px 8px 0 rgba(33, 33, 33, 0.2)",
      outline: "0",
    },
    rootPressed: {
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.themePrimary}`,
      boxShadow: "0 2px 8px 0 rgba(33, 33, 33, 0.4)",
      color: theme.palette.themePrimary,
      outline: "0",
    },
  });
}

function alternateButtonStyles(theme: ITheme) {
  return mergeStyleSets({
    root: {
      alignItems: "center",
      background: "none",
      color: theme.palette.black,
      display: "flex",
      fontSize: "16px",
      gap: "24px",
      justifyContent: "flex-start",
      padding: "0",
      span: {
        display: "flex",
        fontWeight: "normal",
        gap: "10px",
        height: "30px",
        margin: 0,
        alignItems: "center",
      },
      "&.is-disabled": {
        backgroundColor: theme.palette.white,
        color: "#9e9e9e",
      },
    },
    rootFocused: {
      backgroundColor: "none",
      boxShadow: "none",
      color: theme.palette.black,
      span: {
        fontWeight: "600",
      },
    },
    rootHovered: {
      backgroundColor: "none",
      boxShadow: "none",
      color: theme.palette.black,
      span: {
        fontWeight: "600",
      },
    },
    rootPressed: {
      backgroundColor: "none",
      boxShadow: "none",
      color: theme.palette.themePrimary,
      span: {
        fontWeight: "600",
      },
    },
    buttonIcon: {
      display: "flex",
      alignItems: "center",
      height: "24px",
      width: "12px",
      svg: {
        maxWidth: "100%",
      },
    },
  });
}

type BomButtonProps = {
  children: ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
  visibility: ButtonVisibility | undefined;
  onClick?: () => void;
};

export function BomButton(props: Readonly<BomButtonProps>) {
  const theme = useTheme();

  const { children, disabled, icon, visibility, onClick } = props;

  const possibleStyles = useMemo(
    () => ({
      secondary: secondaryButtonStyles(theme),
      alternate: alternateButtonStyles(theme),
    }),
    [theme],
  );
  const appliedStyle = useMemo(
    () => pathOr({}, [visibility ?? ""], possibleStyles),
    [theme, visibility],
  );

  if (or(isNil(visibility), equals(visibility, "hidden"))) {
    return null;
  }

  return (
    <PrimaryButton
      styles={appliedStyle}
      disabled={disabled}
      onClick={onClick}
      style={{ fontSize: "16px" }}
    >
      {equals(visibility, "alternate") && (
        <Stack className={pathOr("", ["buttonIcon"], appliedStyle)}>
          <BasicArrowForwardBold />
        </Stack>
      )}
      <Stack horizontal style={{ gap: "10px" }}>
        {icon}
        {children}
      </Stack>
    </PrimaryButton>
  );
}

export function BomButtons(props: BomButtonsProps) {
  const { loading, openMail, sendToCart, toggle } = props;

  const bomButtonsStyled = bomButtonsStyle();
  const { buttonOrder, visibility, exposure } = useContext(BomContext);
  const {
    guiTO,
    actions: { setCrossReferencesCharacteristics },
  } = useConfigurationContext();
  const { t } = useTranslation();
  const { track } = useAnalyticsContext();
  const notReady = equals(guiTO?.rootContainer.readyState, "NOT_READY");

  function onReset() {
    toggle("reset")();
    setCrossReferencesCharacteristics({});
  }

  const BUTTONS = useMemo(
    () => ({
      request: (
        <BomButton
          key={`bom-button-request`}
          onClick={track("request", () => openMail(ORDER_TYPE.STANDARD_ORDER))}
          visibility={
            retrieveButtonVisibility(exposure.request)
              ? visibility.request
              : "hidden"
          }
          disabled={notReady}
        >
          {t("t:common.button.sendRequest")}
        </BomButton>
      ),
      eplan: (
        <BomButton
          key={`bom-button-eplan`}
          onClick={track("eplan", () => openMail(ORDER_TYPE.E_PLAN_ORDER))}
          visibility={
            retrieveButtonVisibility(exposure.eplan)
              ? visibility.eplan
              : "hidden"
          }
          disabled={notReady}
        >
          {t("t:common.button.EPlanMacroRequest")}
        </BomButton>
      ),
      cart: (
        <BomButton
          key={`bom-button-cart`}
          onClick={track("cart", sendToCart)}
          visibility={
            retrieveButtonVisibility(exposure.cart) ? visibility.cart : "hidden"
          }
          disabled={or(notReady, loading.cart)}
        >
          {t("t:common.button.cart")}
          {loading.cart && (
            <Spinner
              className={bomButtonsStyled.loading}
              size={SpinnerSize.small}
            />
          )}
        </BomButton>
      ),
      share: (
        <BomButton
          key={`bom-button-share`}
          onClick={track("share", toggle("share"))}
          visibility={
            retrieveButtonVisibility(exposure.share)
              ? visibility.share
              : "hidden"
          }
        >
          {t("t:common.button.shareConfiguration")}
        </BomButton>
      ),
      reset: (
        <BomButton
          key={`bom-button-reset`}
          onClick={track("reset", onReset)}
          visibility={
            retrieveButtonVisibility(exposure.reset)
              ? visibility.reset
              : "hidden"
          }
        >
          {t("t:common.button.resetConfiguration")}
        </BomButton>
      ),
      copy: (
        <BomButton
          key={`bom-button-copy`}
          onClick={track("copy", toggle("copy"))}
          visibility={
            retrieveButtonVisibility(exposure.copy) ? visibility.copy : "hidden"
          }
          disabled={notReady}
        >
          {t("t:common.button.copy")}
        </BomButton>
      ),
    }),
    [loading, notReady, sendToCart, toggle, openMail],
  );

  return <>{map((buttonName) => path([buttonName], BUTTONS), buttonOrder)}</>;
}
