import { mergeStyleSets } from "@fluentui/react";
import { equals } from "ramda";
import { useEffect, useMemo } from "react";

import {
  ComponentName,
  registerDefaultConfiguratorComponents,
} from "@encoway/cui-configurator-components";

import { useConfigurationContext } from "../../../context/useConfiguration";
import { TabContextProvider } from "../../../context/useTab";
import { useVisualization } from "../../../hooks/useVisualization";
import { mediaQuery } from "../../../theme";
import { Visualization } from "../../visualization/Visualization";
import { Configurator } from "../Configurator";
import { Bom } from "../bom/Bom";
import { ConfDisplay } from "../components/ConfDisplay";
import { ConfInput } from "../components/ConfInput";
import { ConfRadio } from "../components/ConfRadio";
import { ConfSelect } from "../components/ConfSelect";
import { ConfTable } from "../components/ConfTable";
import { ConfEpri } from "../components/epri/ConfEpri";
import { ConnectorParameter } from "./components/ConnectorParameter";
import { ConnectorSection } from "./components/ConnectorSection";
import { ConnectorTab } from "./components/ConnectorTab";
import { SectionCenter } from "./components/sections/SectionCenter";

function configurationStyle() {
  return mergeStyleSets({
    root: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "0",
    },
    configurator: {
      flex: "1",
      padding: ".5em 0 .5em .5em",
      [mediaQuery.md]: {
        padding: ".5em 0 0",
      },
    },
    bom: {
      flex: "0 0 286px",
      padding: ".5em .5em 0",
      [mediaQuery.md]: {
        padding: ".5em 1em 1em",
      },
    },
    wrapper: {
      display: "grid",
      gridTemplateColumns: "882px 286px",
      [mediaQuery.md]: {
        flexDirection: "column",
      },
    },
  });
}

export function ConnectorConfigurator() {
  const configurationStyled = useMemo(() => configurationStyle(), []);
  const { root, configurator, wrapper, bom } = configurationStyled;
  const { cfg } = useConfigurationContext();
  const { visSettings } = useVisualization();

  useEffect(() => {
    registerDefaultConfiguratorComponents()
      .override(ComponentName.Section, ConnectorSection)
      .override(ComponentName.DummyViewport, ConnectorTab)
      .override(ComponentName.Parameter, ConnectorParameter)
      .override(ComponentName.DisplayOnly, (props: any) => (
        <ConnectorParameter {...props}>
          <ConfDisplay {...props} />
        </ConnectorParameter>
      ))
      .override(ComponentName.RadioButton, (props: any) => (
        <ConnectorParameter {...props}>
          <ConfRadio {...props} />
        </ConnectorParameter>
      ))
      .override(ComponentName.Dropdown, (props: any) => (
        <ConnectorParameter {...props}>
          <ConfSelect {...props} />
        </ConnectorParameter>
      ))
      .override(ComponentName.FilterDropdown, (props: any) => (
        <ConnectorParameter {...props}>
          <ConfSelect {...props} />
        </ConnectorParameter>
      ))
      .override(ComponentName.InputField, (props: any) => (
        <ConnectorParameter {...props}>
          <ConfInput {...props} />
        </ConnectorParameter>
      ))
      .override(ComponentName.Epri, ConfEpri)
      .override("table", ConfTable)
      .override("center", SectionCenter)
      .override(ComponentName.Spinner, () => null);
  }, [cfg]);

  return (
    <div className={root}>
      <TabContextProvider>
        <div>{equals(visSettings.enabled, "top") && <Visualization />}</div>
        <div className={wrapper}>
          <div className={configurator}>
            <Configurator />
          </div>
          <div className={bom}>
            <Bom />
          </div>
        </div>
      </TabContextProvider>
    </div>
  );
}
