import { AxiosInstance } from "axios";
import { path, replace } from "ramda";

import { Language } from "@encoway/c-services-js-client";

import { LanguageMapping } from "../context/useSettings";

export async function fetchLanguages(
  axios: AxiosInstance,
  language: string,
): Promise<Language[]> {
  const res = await axios.get<{ languages: Language[] }>(
    `api/catalog/languages`,
    { headers: { "Accept-Language": language } },
  );
  return res.data.languages;
}

export async function fetchResource<T>(
  axios: AxiosInstance,
  uri: string,
  language: string,
): Promise<T> {
  const replacedSSLUri = replace(
    /https?:\/\/([^/:]+)(:\d+)?/,
    "https://$1",
    uri,
  );
  const translationRes = await axios.get<T>(replacedSSLUri, {
    headers: { "Accept-Language": language },
  });
  return translationRes.data;
}

export async function fetchLangMapping(
  axios: AxiosInstance,
): Promise<LanguageMapping> {
  const res = await axios.post<{ languages: Language[] }>(
    `api/catalog/products/_selection`,
    {
      limit: 1000,
      filter: { productsFilter: { ids: ["lang_mapping"] } },
      characteristics: { allCharacteristics: true },
    },
    { headers: { "Accept-Language": "de-DE" } },
  );
  const langMappingJsonURI = path<string>(
    [
      "products",
      0,
      "characteristicValues",
      "lang_mapping_file",
      "values",
      0,
      "uri",
    ],
    res.data,
  );
  if (langMappingJsonURI) {
    return (
      (await fetchResource<LanguageMapping>(
        axios,
        langMappingJsonURI,
        "de-DE",
      )) ?? {}
    );
  }
  return {};
}
