import axios from "axios";
import { isEmpty, isNil } from "ramda";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { InstanceProps, SettingsProps } from "setting";

import { Http } from "@encoway/c-services-js-client";

import { fetchLangMapping } from "../service/appService";
import { createSettings } from "../settings";
import { getScriptOrigin } from "../utils";

export interface LanguageMapping {
  [key: string]: [string, string];
}

export type EncowayEnv =
  | {
      baseUrl: string;
      openIdIssuerUrl: string;
      clientId: string;
      ssoActive: "true" | "false";
      sessionTrackingInterval: number;
      validSessionLimit: number;
      reactAppEnvironment: string;
    }
  | undefined;

const SettingsContext = createContext<
  | ({ settings: SettingsProps; langMapping: LanguageMapping } & InstanceProps)
  | undefined
>(undefined);

export function SettingsContextProvider({
  children,
}: Readonly<{ children: ReactNode }>) {
  const [envs, setEnvs] = useState<EncowayEnv>();
  const settings = useMemo<SettingsProps>(() => createSettings(envs), [envs]);
  const [langMapping, setLangMapping] = useState<LanguageMapping>({});

  useEffect(() => {
    (async () => {
      const origin = getScriptOrigin();
      const newEnvs: { data: EncowayEnv } = await axios.get(
        origin + "/environment.json",
      );
      setEnvs(newEnvs.data);
    })();
  }, []);

  const instances: InstanceProps = useMemo(
    () => ({
      axios: axios.create({
        baseURL: settings.showroom.url,
        auth: {
          username: settings.showroom.auth.username,
          password: settings.showroom.auth.password,
        },
      }),
      axiosSimple: axios.create({
        baseURL: settings.showroom.url,
      }),
      http: Http.Basic(
        settings.showroom.auth.username,
        settings.showroom.auth.password,
      ),
    }),
    [settings],
  );

  useEffect(() => {
    fetchLangMapping(instances.axios).then((r) => setLangMapping(r));
  }, [instances]);

  const state = useMemo(
    () => ({ settings, langMapping, ...instances }),
    [settings, langMapping, instances],
  );
  return (
    <SettingsContext.Provider value={state}>
      {isEmpty(langMapping) ? null : children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (isNil(context)) {
    throw new Error("useSettings must be used within SettingsContext");
  }
  return context;
}
