import { Stack } from "@fluentui/react";
import { Bus } from "baconjs";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import {
  ComponentName,
  registerDefaultConfiguratorComponents,
} from "@encoway/cui-configurator-components";
import { Configurator as EncowayConfigurator } from "@encoway/react-configurator";

import { useConfigurationContext } from "../../context/useConfiguration";
import { ConfCheckbox } from "./components/ConfCheckbox";
import { ConfCounterpartRadio } from "./components/ConfCounterpartRadio";
import { ConfDisplay } from "./components/ConfDisplay";
import { ConfInput } from "./components/ConfInput";
import { ConfParameter } from "./components/ConfParameter";
import { ConfRadio } from "./components/ConfRadio";
import { ConfSection } from "./components/ConfSection";
import { ConfSelect } from "./components/ConfSelect";
import { ConfTab } from "./components/ConfTab";
import { ConfEpri } from "./components/epri/ConfEpri";

export function Configurator() {
  const { cfg, eventBus } = useConfigurationContext();
  const { i18n } = useTranslation();

  useEffect(() => {
    registerDefaultConfiguratorComponents()
      .override(ComponentName.DummyViewport, ConfTab)
      .override(ComponentName.Section, ConfSection)
      .override(ComponentName.Parameter, ConfParameter)
      .override(ComponentName.DisplayOnly, (props: any) => (
        <ConfParameter {...props}>
          <ConfDisplay {...props} />
        </ConfParameter>
      ))
      .override(ComponentName.RadioButton, (props: any) => (
        <ConfParameter {...props}>
          <ConfRadio {...props} />
        </ConfParameter>
      ))
      .override("counterpartRadioButton", (props: any) => (
        <ConfCounterpartRadio {...props} />
      ))
      .override(ComponentName.Dropdown, (props: any) => (
        <ConfParameter {...props}>
          <ConfSelect {...props} />
        </ConfParameter>
      ))
      .override(ComponentName.InputField, (props: any) => (
        <ConfParameter {...props}>
          <ConfInput {...props} />
        </ConfParameter>
      ))
      .override(ComponentName.Epri, ConfEpri)
      .override(ComponentName.Spinner, () => null)
      .override("identicalconfbutton", ConfCheckbox);
  }, [cfg]);

  if (cfg) {
    return (
      <div id="encoway-configurator">
        <Stack.Item align="auto">
          <div id="cui" style={{ flex: 1, position: "relative" }}>
            <EncowayConfigurator
              lang={i18n.language}
              config={cfg}
              eventBus={eventBus as Bus<unknown>}
              options={{
                dontDeleteOnIdChange: false,
                deleteOnUnmount: false,
                hideLinkedTree: true,
              }}
              styles={{
                section: {
                  flex: 1,
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                },
                main: {
                  height: "100%",
                  flex: 1,
                },
                aside: {
                  display: "flex",
                  overflow: "auto",
                },
              }}
            />
          </div>
        </Stack.Item>
      </div>
    );
  }

  return <Navigate to="/" />;
}
