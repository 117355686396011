import { ExtendedProduct } from "product";
import { equals, isEmpty, isNil, path } from "ramda";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { ProductContext } from "./useProducts";
import { useSettings } from "./useSettings";

interface FooterLink {
  text: string;
  url: string;
  isCookie?: boolean;
}

interface PartnerInfo {
  name: string;
  logoUrl: string | undefined;
  headerContent: string | undefined;
  footerContent: string | undefined;
  footer: FooterLink[][];
  languages: LanguageOption[];
}

interface LanguageOption {
  value: string;
  label: string;
}

const initialContext: PartnerInfo = {
  name: "Lapp",
  headerContent: undefined,
  footerContent: undefined,
  logoUrl: undefined,
  languages: [
    {
      label: "Deutsch",
      value: "de-DE",
    },
    {
      label: "English",
      value: "en-US",
    },
  ],
  footer: [],
};

const PartnerContext = createContext<PartnerInfo | undefined>(undefined);

enum STATUS {
  FAILED,
  FETCHING,
  PENDING,
}

export function PartnerProvider({
  children,
}: Readonly<PropsWithChildren<object>>) {
  const { axios } = useSettings();
  const { products, getProducts } = useContext(ProductContext);
  const { partnerId, locale } = useParams<{
    partnerId: string;
    locale: string;
  }>();
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo | undefined>(
    undefined,
  );
  const isPendingRef = useRef(STATUS.PENDING);

  const getPartnerInfo = useCallback(
    async (id: string) => {
      if (
        !equals(isPendingRef.current, STATUS.PENDING) ||
        isNil(products) ||
        isEmpty(products)
      ) {
        return;
      }
      isPendingRef.current = STATUS.FETCHING;

      const partner: ExtendedProduct | undefined = path([id], products);
      if (!partner) {
        const newProducts = await getProducts(id);
        const newPartner = path([id], newProducts);
        if (newPartner) {
          isPendingRef.current = STATUS.PENDING;
          return newPartner;
        }
        const defaultProducts = await getProducts("default_partner");
        const defaultPartner = path(["default_partner"], defaultProducts);
        if (defaultPartner) {
          isPendingRef.current = STATUS.FAILED;
          return defaultPartner;
        }
      }
      return partner;
    },
    [products],
  );

  useEffect(() => {
    isPendingRef.current = STATUS.PENDING;
  }, [locale]);

  useEffect(() => {
    (async () => {
      const partner = await getPartnerInfo(partnerId || "default_partner");
      if (partner) {
        const partnerURI = path<string>(
          ["characteristicValues", "partner", "values", 0, "uri"],
          partner,
        );
        const partnerLogo = path<string>(
          ["characteristicValues", "partner_logo", "values", 0, "uri"],
          partner,
        );
        const partnerFooterContent = path<string>(
          ["characteristicValues", "partner_content_footer", "values", 0],
          partner,
        );
        const partnerHeaderContent = path<string>(
          ["characteristicValues", "partner_content_header", "values", 0],
          partner,
        );
        let partnerData: PartnerInfo = {
          ...initialContext,
          logoUrl: partnerLogo,
          footerContent: partnerFooterContent,
          headerContent: partnerHeaderContent,
        };

        if (partnerURI) {
          const { data } = await axios.get<PartnerInfo>(partnerURI);
          partnerData = {
            ...partnerData,
            ...data,
          };
        }
        setPartnerInfo(partnerData);
      }
    })();
  }, [partnerId, products]);

  const contextValue = useMemo(() => partnerInfo, [partnerInfo]);

  return (
    <PartnerContext.Provider value={contextValue}>
      {children}
    </PartnerContext.Provider>
  );
}

export function usePartner() {
  return useContext(PartnerContext);
}
